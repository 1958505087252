export const environment = {
  production: true,

  apid: "http://localhost:3001/aWNiZXU=/api",
  apiAuthd: "http://localhost:3001/aWNiZXU=",

  api: "https://api.easytecsis.com.br/aWNiZXU=/api",
  apiAuth: "https://api.easytecsis.com.br/aWNiZXU=",

  viaCep: "//viacep.com.br/ws",
};
